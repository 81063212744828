@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
.Presentation {
    background-color: rgb(27,27,30);
    height: 90vh;
   
    .greeting {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 3em;
        padding-left: 4%;
        
        #n1 {
            left: -17%;
            top: 0.6em;
            position: relative;
        }
        .greeting-p {
            padding-right: 20%;
            margin: 0;
        }
        #name {
            line-height: 1;
            margin-bottom: 0.3em;
            width: 25%;
        }
        .special-p {
            font-family: 'Roboto', sans-serif;
            color: #DB4747;
            font-weight: bold;
            margin: 0;

        }
        .presentaion-p {
            width: 35%;
            margin-top: 2em;
            padding-left: 5.5%;
        }
        .social {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 27%;
            height: 7%;
        }
        line {
            stroke: white;
            stroke-width: 1;
        }
        .icon {
            font-size: 18px;
        }
        .circle-svg {
            position: absolute;
            right: 27%;
            top: 28%;
        }
    }
    @media (max-width: 600px) { 
        height: 100vh;
        .greeting {
            
            /* justify-content: center; */
            /* flex-direction: column; */
            /* align-items: center; */
            margin-top: 5em;
            /* padding-left: 4%; */
            /* width: 100%; */
            padding-top: 4em;
            #n1 {
                left: -36%;
                top: 0.6em;
                position: relative;
                font-size: 57px;
            }
            .greeting-p {
                /* padding-right: 20%; */
                padding-right: 68%;
                margin: 0;
            }
            #name {
                line-height: 1;
                margin-bottom: 0;
                width: 83%;
                font-size: 3em;
                margin: 0.2em 0;
            }
            .special-p {
                font-family: 'Roboto', sans-serif;
                color: #DB4747;
                font-weight: bold;
                margin: 0;
    
            }
            .presentaion-p {
                width: 93%;
                margin: auto;
                padding-left: 5.5%;
                
            }
            .social {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                width: 96%;
                /* height: 7%;*/
            }
            line {
                stroke: white;
                stroke-width: 1;
            }
            .icon {
                font-size: 18px;
            }
            .circle-svg {
                position: absolute;
                right: 7%;
                top: 23%;
            }
        }
    }
}