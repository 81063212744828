.App {
    display: grid;
    .progress-icon {
        color: white;
        position: fixed;
        z-index: 1000;
        transform: scale(.1);
        bottom: -19%;
        left: -43%;
        @media (max-width: 600px) { 
            transform: scale(0.2);
            bottom: 50%;
            left: -39%;
          }
    }
    nav {
        transform: rotate(90deg);
        position: fixed;
        right: -7%;
        top: 48.5%;
        display: flex;
        z-index: 10;
        @media (max-width: 600px) { 
            display: none;
        }
        a {
            text-decoration: none;
            color: white;
            margin: 0 1.2em;
            font-weight: 300;
            font-size: 13px;
            &:hover {
            cursor: pointer;
            }
        }
    }
}